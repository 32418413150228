<template>
  <div class="mobile__menu__container column" :class="{ show: showMenu }">
    <div class="mobile__menu column">
      <Link
        @click="$emit('closeMenu')"
        text="nav-01"
        icon="fa-solid fa-credit-card"
        :href="`/${$store.state.language}/pricing`"
      />
      <Link
        @click="$emit('closeMenu')"
        text="nav-15"
        icon="fa-solid fa-file-code"
        :href="`/${$store.state.language}/documentation`"
      />
      <Link
        @click="$emit('closeMenu')"
        text="nav-02"
        icon="fa-solid fa-user"
        :href="`https://app.sharedex.co/auth/login?language=${$store.state.language}`"
        :external="true"
      />
      <Link
        @click="$emit('closeMenu')"
        text="nav-03"
        icon="fa-solid fa-user-plus"
        :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
        :external="true"
      />

      <div class="line"></div>

      <Languages @updateLanguage="$emit('updateLanguage', $event)" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import Link from '../components/Link'
import Languages from './components/Languages'

export default {
  name: 'Mobile',
  emits: ['updateLanguage', 'closeMenu'],
  props: {
    showMenu: { type: Boolean, default: false },
  },
  components: { Link, Languages },
  setup() {
    const showLanguages = ref(false)

    return {
      showLanguages,
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile__menu__container {
  position: fixed;
  top: 0;
  left: -100vw;
  width: calc(100% - 30px);
  height: calc(100% - 120px);
  z-index: 10;
  padding: 100px 15px 20px 15px;
  background-color: white;
  transition: left 0.2s linear;
  overflow-y: scroll;
  align-items: center;

  .mobile__menu {
    max-width: 300px;
  }

  &.show {
    left: 0;
    transition: left 0.2s linear;
  }

  @media (min-width: 1100px) {
    display: none !important;
  }
}
</style>
