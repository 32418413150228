<template>
  <Header />
  <router-view class="container" />
  <CookieBanner />
  <Footer v-if="showFooter" />
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import Header from '@components/navigation/header'
import Footer from '@components/navigation/footer'
import CookieBanner from '@components/cookieBanner/CookieBanner.vue'

export default {
  name: 'App',
  components: { Header, Footer, CookieBanner },
  setup() {
    const store = useStore()
    const showFooter = ref(false)

    onMounted(async () => {
      await store.dispatch('ping')
      await store.dispatch('pingDemo')

      // document.addEventListener('click', (event) => {
      //   const clickedElement = event.target
      //   const anchorEl =
      //     clickedElement.tagName.toLowerCase() === 'a'
      //       ? clickedElement
      //       : clickedElement.closest('a')
      //       ? clickedElement.closest('a')
      //       : null

      //   if (anchorEl) store.dispatch('saveClick', anchorEl.href)
      // })

      setTimeout(() => {
        showFooter.value = true
      }, 100)
    })

    return {
      showFooter,
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import '@/assets/variables.scss';
@import '@/assets/classes.scss';
@import '@/assets/media.scss';
@import '@/assets/transition.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

#app {
  width: 100%;
  margin: 0;
  color: var(--dark-10);
  overflow-x: hidden;
}
</style>
